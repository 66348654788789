import React, { useState, useEffect, useRef } from 'react'
import BotCanvas from './Components/BotCanvas';
import AudioToText from './Components/AudioToText'
import { Line } from 'rc-progress';

import './App.css'

const App = () => {

  const [language, setLanguage] = useState("हिं");
  const [showDropdown, setShowDropdown] = useState(false);

  // Talk Animations
  const [isTalking, setIsTalking] = useState(false)
  const [talkAnimation, setTalkAnimation] = useState("")

  //Thinking
  const [isThinking, setIsThinking] = useState(false)


  // LOADING
  const [modelLoaded, setModelLoaded] = useState(false);
  const [modelProgress, setModelProgress] = useState(15);

  const [toggleLoading, setToggleLoading] = useState(false)

  const handleModelProgress = (progress) => {
    setModelProgress(progress);
  };

  const handleModelLoad = () => {
    setModelLoaded(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setToggleLoading(true)
    }, 5000);
  }, [modelLoaded])



  // START BUTTON
  const [startClicked, setStartClicked] = useState(false)

  const dropdownRef = useRef(null);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setShowDropdown(false);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  const setContainerHeights = () => {
    const headerCont = document.querySelector('.header-cont');
    const botCont = document.querySelector('.bot-cont');
    const responseCont = document.querySelector('.response-cont');
    const recordingCont = document.querySelector('.recording-cont');

    const viewportHeight = window.innerHeight;
    headerCont.style.height = `${viewportHeight * 0.05}px`;
    botCont.style.height = `${viewportHeight * 0.6}px`;
    responseCont.style.height = `${viewportHeight * 0.175}px`;
    recordingCont.style.height = `${viewportHeight * 0.175}px`;
  };

  useEffect(() => {
    window.addEventListener('resize', setContainerHeights);
    setContainerHeights();

    return () => {
      window.removeEventListener('resize', setContainerHeights);
    };
  }, []);


  return (
    <>
      {!startClicked &&
        <div className='loading-screen'>
          <div className="load-start-cont">
            {!toggleLoading &&
              <div className='loading-bar'>
                <Line percent={modelProgress} strokeWidth={1.25} strokeColor="#8258d6" trailColor="#44444480" />
              </div>
            }
            {toggleLoading && <button className='start-button ff-sb' onClick={() => setStartClicked(true)}>START</button>}
          </div>
          <div className="xrv-logo">
            <div className='xrv-logo-text ff-l'>powered by</div>
            <img className="xrv-logo-img" src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRVizion/xrv.png" alt=""></img>
          </div>
        </div>
      }

      <div className='header-cont'>
        <div id='dropdown-cont' style={{ paddingTop: language === 'हिं' ? '0.45rem' : '0.25rem' }} className={`dropdown-cont ${showDropdown ? 'active' : ''}`} onClick={() => setShowDropdown(!showDropdown)} ref={dropdownRef}>
          {!showDropdown && (
            <>
              <span id='selected-lang' className='selected-lang ff-m ff-sb' style={{ fontWeight: "900", fontSize: language === 'हिं' ? '0.9rem' : '0.8rem', margin: language === 'हिं' ? '0rem 0.15rem' : '0' }}>{language}</span>
              <img className="dropdown-image" src='https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRVizion/JEET/arrowDown.png' alt='Arrow Down' />
            </>
          )}
          {showDropdown && (
            <ul>
              <li className="lang-option ff-m" onClick={() => handleLanguageChange('EN')}>EN</li>
              <li className="lang-option ff-m" onClick={() => handleLanguageChange('हिं')}>हिं</li>
            </ul>
          )}
        </div>
      </div>

      <BotCanvas isTalking={isTalking} talkAnimaton={talkAnimation} onLoad={handleModelLoad} onProgress={handleModelProgress} isThinking={isThinking}/>
      <AudioToText language={language} isTalking={isTalking} setIsTalking={setIsTalking} setTalkAnimation={setTalkAnimation} startClicked={startClicked} setIsThinking={setIsThinking}/>
    </>
  )
}

export default App