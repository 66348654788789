import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import { BeatLoader } from 'react-spinners'

import { IoMdMic } from 'react-icons/io'

const sampleRate = 16000;

const getMediaStream = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: "default",
        sampleRate: sampleRate,
        sampleSize: 16,
        channelCount: 1,
      },
      video: false,
    });
    return stream;
  } catch (error) {
    console.error("Error accessing media stream:", error);
  }
};

const AudioToText = (props) => {
  const { isTalking, setIsTalking, setTalkAnimation, startClicked, setIsThinking } = props

  const [connection, setConnection] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [currentRecognition, setCurrentRecognition] = useState("");

  const processorRef = useRef();
  const audioContextRef = useRef();
  const audioInputRef = useRef();

  const [buttonText, setButtonText] = useState("Tap to speak")
  // const [playbackConsent, setPlaybackConsent] = useState(false)
  // const [playbackIOSText, setplaybaclIOSText] = useState("TEXT")

  const [startingToRecord, setStartingToRecord] = useState(false)
  const [responseReady, setResponseReady] = useState(true)

  const recordingStoppedByTimerRef = useRef(false);


  const session = useRef(0);

  const url = "https://api.jitto.xrvizion.com"

  //AUDIO
  const audioRef = useRef(new Audio());
  const audioRefAlt = useRef(new Audio());
  const audioRefAlt2 = useRef(new Audio());

  let playPromise
  const stopAudio = async () => {
    if (startClicked) {
      playPromise = audioRef.current.play();
    }
    if (playPromise !== undefined) {
      try {
        await playPromise; // Wait for the play() Promise to settle
        audioRef.current.pause();
        // console.warn(playPromise)
        setIsTalking(false)
      } catch (error) {
        // console.log('An error occurred:', error);
      }
    }
  };

  useEffect(() => {
    if (startClicked) {
      playPromise = audioRef.current.play();
    }
  }, [startClicked])

  // ANIMATIONS
  useEffect(() => {
    const handlePlay = () => {
      if (isTalking) {
        // Start playing a random talking animation
        const animations = ['Talking_1', 'Talking_2', 'Talking_3'];
        const animation = animations[Math.floor(Math.random() * animations.length)];

        // console.log("PLAYING", animation)
        // Use your method for playing the animation here
        // playAnimation(animation);
        setTalkAnimation(animation)
      }
    };
    audioRef.current.addEventListener('play', handlePlay);

    // Add event listener for the ended event
    const handleEnded = () => {
      setIsTalking(false)
    };
    audioRef.current.addEventListener('ended', handleEnded);

    // Remove event listeners when component is unmounted
    return () => {
      audioRef.current.removeEventListener('play', handlePlay);
      audioRef.current.removeEventListener('ended', handleEnded);
    };
  }, [isTalking, setIsTalking, setTalkAnimation]);


  // SOCKET IO CONNECTION AND MESSAGES
  useEffect(() => {
    // console.log("Connecting to web socket...")
    const socket = io.connect(url);

    socket.on("connect", () => {
      // console.log("connected", socket.id);
      setConnection(socket);
    });

    socket.on("completedResponse", (data) => {
      setIsTalking(true)
      setIsThinking(false)
      setResponseReady(true)

      // Check if the audio context is already closed
      if (audioContextRef.current.state !== 'closed') {
        // Close the audio context before starting a new playback
        audioContextRef.current.close();
      }

      // Create a new audio context for the next playback
      audioContextRef.current = new window.AudioContext();

      // Set the audio session type to 'playback' if supported
      if (navigator.audioSession) {
        // setPlaybackConsent("PLAYBACK")
        navigator.audioSession.type = 'playback';
      }

      // Set the current recognition text
      setCurrentRecognition(data.completedText);

      // Set the audio element src attribute and start playback
      audioRef.current.src = url + `/${data.url}`;
      audioRef.current.crossOrigin = "anonymous";
      audioRef.current.play();

      // Store the session ID if it's a new session
      if (data.newSession) {
        // localStorage.setItem('sessionId', data.sessionId);
        session.current = data.sessionId
      }
    });

    socket.on("playSong", () => {
      // console.log("playsong")
      setIsTalking(true)
      setIsThinking(false)
      setResponseReady(true)

      audioRef.current.src = 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRVizion/JEET/JITTO_Jingle.wav'
      audioRef.current.crossOrigin = "anonymous"
      audioRef.current.play();
      setCurrentRecognition("♪")
    });

    socket.on("serverError", () => {
      setIsTalking(true)
      setIsThinking(false)
      setResponseReady(true)

      // console.log("Server Error")

      audioRef.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/butati_error.wav"
      audioRef.current.crossOrigin = "anonymous"
      audioRef.current.play();
      setCurrentRecognition("Sorry, I didn't get that. Can you say it again?")
    })

    socket.on("disconnect", () => {
      // console.log("disconnected", socket.id);
    });

    socket.on("final", () => {

      if (recordingStoppedByTimerRef.current === false) {
        // console.log("FINAL", recordingStoppedByTimerRef.current);
        clearTimeout(timeoutRef.current);


        if (connection) connection.emit("endStream");
        // setIsThinking(true)
        processorRef.current?.disconnect();
        audioInputRef.current?.disconnect();
        if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
          audioContextRef.current.close()
        }

        if (navigator.audioSession) {
          // setplaybaclIOSText("PLAYBACK")
          navigator.audioSession.type = 'playback';
        }

        audioRefAlt.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/stop-13692.mp3"
        audioRefAlt.current.crossOrigin = "anonymous"
        audioRefAlt.current.play();

        setIsRecording(false);
        setResponseReady(false)
        setIsThinking(true)
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const timerRef = useRef(null);

  useEffect(() => {
    if (!responseReady) {
      // Clear any existing timer
      if (timerRef.current) clearTimeout(timerRef.current);

      // Start a new timer
      timerRef.current = setTimeout(() => {
        // console.warn("RESPONSE LOST")
        setResponseReady(true);
        setCurrentRecognition("");
        timerRef.current = null;
      }, 10000); // Set the time here. 10000ms = 10s
    }
    // Cleanup function
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [responseReady]);

  // RESPONSE CONTAINER SCROLL ADJUSTMENT
  const responseContRef = useRef(null);
  useEffect(() => {
    // Add a new useEffect hook to update the styles of the responseCont element
    const responseCont = responseContRef.current;

    function updateStyles() {
      if (responseCont.scrollHeight > responseCont.clientHeight) {
        // Content is overflowing, remove the "centered" class
        responseCont.classList.remove("centered");
      } else {
        // Content is not overflowing, add the "centered" class
        responseCont.classList.add("centered");
      }
    }

    // Call the updateStyles function whenever the content of the container changes
    responseCont.addEventListener("DOMSubtreeModified", updateStyles);

    // Clean up the event listener when the component unmounts
    return () => {
      responseCont.removeEventListener("DOMSubtreeModified", updateStyles);
    };
  }, [])


  // RECORDING BUTTON UI
  useEffect(() => {
    const recButton = document.querySelector('.ui-rec-btn')
    if (isRecording) {
      recButton.classList.add('active')
      setButtonText("Listening...")
      setStartingToRecord(false)
    }
    else {
      recButton.classList.remove('active')
      setButtonText("Tap to speak")
    }
  }, [isRecording])


  // STOP RECORDING FUNCTIONALITY
  // useEffect(() => {
  //   const handlePointerUp = () => {
  //     // console.log("Stopped Recording");
  //     setButtonText("Hold to speak")
  //     if (!isRecording) return;
  //     if (connection) connection.emit("endStream");
  //     processorRef.current?.disconnect();
  //     audioInputRef.current?.disconnect();
  //     if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
  //       audioContextRef.current.close()
  //     }

  //     if (navigator.audioSession) {
  //       setplaybaclIOSText("PLAYBACK")
  //       navigator.audioSession.type = 'playback';
  //     }

  //     setIsRecording(false);
  //   };

  //   document.addEventListener("pointerup", handlePointerUp);
  //   document.addEventListener("touchend", handlePointerUp);

  //   return () => {
  //     document.removeEventListener("pointerup", handlePointerUp);
  //     document.addEventListener("touchend", handlePointerUp);
  //   };
  // }, [connection, isRecording]);


  const timeoutRef = useRef(null);

  const stopRecording = () => {
    // console.log("Stopped Recording");
    // setButtonText("Hold to speak")
    if (!isRecording) return;
    if (connection) connection.emit("endStream");
    setIsThinking(true)
    processorRef.current?.disconnect();
    audioInputRef.current?.disconnect();
    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close()
    }

    if (navigator.audioSession) {
      // setplaybaclIOSText("PLAYBACK")
      navigator.audioSession.type = 'playback';
    }

    setIsRecording(false);
  }

  useEffect(() => {
    if (!responseReady) {
      setButtonText("Thinking...")
    } else {
      setButtonText("Tap to speak")
    }
  }, [responseReady])

  // START RECORDING METHOD
  const startRecording = async () => {

    if (isRecording) {
      // setResponseReady(false)
      // stopRecording()
      // clearTimeout(timeoutRef.current);
      return;
    }

    setStartingToRecord(true)
    recordingStoppedByTimerRef.current = false

    await stopAudio()
    // setButtonText("Release to stop")
    setCurrentRecognition("...")

    if (!connection || isRecording) return;

    // if (!playbackConsent) {
    // setPlaybackConsent(true);
    audioRefAlt2.current.src = "data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEARKwAAIhYAQACABAAZGF0YQAAAAA=";
    audioRefAlt2.current.play();
    // }

    if (navigator.audioSession) {
      // setplaybaclIOSText("PLAY AND RECORD")
      // The Audio Session API is supported
      navigator.audioSession.type = 'play-and-record';
    }

    // let newSession = localStorage.getItem('sessionId') === null
    let newSession = session.current === 0
    let sessionId = newSession ? 0 : session.current

    // console.log(props.language === "EN" ? "en-IN" : "hi-IN", sessionId, newSession)

    connection.emit("startStream", props.language === "EN" ? "en-IN" : "hi-IN", sessionId, newSession);

    const stream = await getMediaStream();
    audioContextRef.current = new window.AudioContext();

    await audioContextRef.current.audioWorklet.addModule(
      "/src/worklets/recorderWorkletProcessor.js"
    );

    audioContextRef.current.resume();
    audioInputRef.current = audioContextRef.current.createMediaStreamSource(
      stream
    );

    processorRef.current = new AudioWorkletNode(
      audioContextRef.current,
      "recorder.worklet"
    );

    processorRef.current.connect(audioContextRef.current.destination);
    audioContextRef.current.resume();
    audioInputRef.current.connect(processorRef.current);

    processorRef.current.port.onmessage = (event) => {
      const audioData = event.data;
      connection.emit("send_audio_data", { audio: audioData });
    };

    audioRefAlt.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/start-13691.mp3"
    audioRefAlt.current.crossOrigin = "anonymous"
    audioRefAlt.current.play();

    setIsRecording(true);

    timeoutRef.current = setTimeout(() => {
      // console.warn('STOPPER FORCEFULLY');
      recordingStoppedByTimerRef.current = true
      // console.warn(recordingStoppedByTimerRef.current)
      if (connection) connection.emit("endStream");
      // setIsThinking(true)
      processorRef.current?.disconnect();
      audioInputRef.current?.disconnect();
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close()
      }

      if (navigator.audioSession) {
        // setplaybaclIOSText("PLAYBACK")
        navigator.audioSession.type = 'playback';
      }

      audioRefAlt.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/Resources/stop-13692.mp3"
      audioRefAlt.current.crossOrigin = "anonymous"
      audioRefAlt.current.play();

      setIsRecording(false);
      setResponseReady(false)
      setIsThinking(true)



      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, 5000);
  };


  useEffect(() => {
    if (startClicked) {

      audioRefAlt2.current.src = "data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEARKwAAIhYAQACABAAZGF0YQAAAAA=";
      audioRefAlt2.current.play();

      setTimeout(() => {
        audioRef.current.src = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRVizion/JEET/Jeet_Greeting.wav";
        audioRef.current.play();
        setIsTalking(true)
        setCurrentRecognition("Hi, I am Jeet, an AI bot by JITEM to assist with queries about the event. How can I help you?")
      }, 1000);
    }

  }, [setIsTalking, startClicked])


  return (
    <>
      {/* <h1 style={{ color: "white", display: "none" }}>{playbackIOSText}</h1> */}
      <div className="response-cont" ref={responseContRef}>
        <div className="gc-response ff-l" style={{ marginLeft: "0.5rem" }}>
          {currentRecognition === "..." ?
            <BeatLoader
              color="#fff"
              margin={2.25}
              size={7.5}
              speedMultiplier={0.8}
            /> : currentRecognition}</div>
      </div>
      <div className="recording-cont">
        {/* <button className="ui-rec-btn active ff-m" onMouseDown={startRecording} onTouchStart={startRecording}>
          <IoMdMic size={"1.65rem"} />
        </button> */}
        <button disabled={startingToRecord || responseReady === false} className="ui-rec-btn active ff-m" onClick={startRecording}>
          <IoMdMic size={"1.65rem"} />
        </button>
        <div className="info-text ff-l">{buttonText}</div>
      </div>
    </>
  );
};

export default AudioToText;
