import React, { useRef, useEffect, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useAnimations, useProgress, Environment } from '@react-three/drei'
import * as THREE from 'three'

const Model = ({ isTalking, talkAnimaton, onLoad, onProgress, isThinking }) => {

  const intervalId = useRef(null);
  const isThinkingRef = useRef(false);
  const isTalkingRef = useRef(false);
  const currentIdleIndex = useRef();
  currentIdleIndex.current = -1;

  // LOADING DATA
  const { active, progress } = useProgress();
  useEffect(() => {
    if (!active) {
      onLoad();
    }
    onProgress(progress);
  }, [active, onLoad, onProgress, progress]);

  const [scale, setScale] = useState(5)
  const [positionY, setPositionY] = useState(-3.05)

  const [greetMessage, setGreetMessage] = useState(false)

  const group = useRef()
  const gltf = useGLTF('https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRVizion/JEET/Baked_all_NLA_8.glb')
  const { actions } = useAnimations(gltf.animations, group)

  const idleAnimationNames = ["Swindle Idle", "Hi_Idle", "uwu_Idle"];
  const idleFaceAnimationNames = ["SwindleIdle_Face", "Hi_Face", "uwuIdle_Face"];

  const playRandomIdleAnimation = () => {
    // console.log("isThinking:", isThinking, "| isTalking: ", isTalking)

    // console.log("currentIdleIndex", currentIdleIndex.current)
    const newIdleIndex = getRandomIdleIndex(currentIdleIndex.current);
    // console.log("newIdleIndex", newIdleIndex)
    currentIdleIndex.current = newIdleIndex;
    const newIdleAnimationName = idleAnimationNames[newIdleIndex];
    const newIdleFaceAnimationName = idleFaceAnimationNames[newIdleIndex];

    // console.log("PLAYING", newIdleAnimationName)
    actions[newIdleAnimationName].reset().play(); // Play new idle
    actions[newIdleFaceAnimationName].reset().play(); // Play new idle face
  };

  const playHoverAndEyes = () => {
    if (!isThinkingRef.current && !isTalkingRef.current) {

      // console.log("Playing HOVER", isThinkingRef.current, isTalkingRef.current)
      actions["Hovering"].reset().play();

      setTimeout(() => {
        if (!isThinkingRef.current && !isTalkingRef.current) {
          // console.log("Playing Eyes", isThinkingRef.current, isTalkingRef.current)
          actions["OneBlink"].reset().play();
        }
      }, 2000);
    }
  }


  const getRandomIdleIndex = (excludeIndex) => {
    let newIndex = Math.floor(Math.random() * 3);
    while (newIndex === excludeIndex) {
      newIndex = Math.floor(Math.random() * 3);
    }
    return newIndex;
  };

  const onAnimationFinished = (finishedAnimation) => {
    if (!isThinkingRef.current && !isTalkingRef.current) {
      // console.warn("Animation finished:", finishedAnimation, "Hovering NOW")
      const faceIndex = idleAnimationNames.indexOf(finishedAnimation);
      actions[finishedAnimation].stop();
      // console.log(idleFaceAnimationNames[faceIndex]);
      actions[idleFaceAnimationNames[faceIndex]].stop();

      playHoverAndEyes()

      setTimeout(() => {
        // console.log("NEXT Animation")
        actions["Hovering"].stop()
        actions["OneBlink"].stop()
        if (!isThinkingRef.current && !isTalkingRef.current) {
          playRandomIdleAnimation() // Play next idle animation after hovering animation
        }
      }, 5000);
    }
  };

  useEffect(() => {
    // Set up idle animations
    idleAnimationNames.forEach((name) => {
      actions[name].setLoop(THREE.LoopOnce, 1);
      actions[name].clampWhenFinished = true;
    });

    idleFaceAnimationNames.forEach((name) => {
      actions[name].setLoop(THREE.LoopOnce, 1);
      actions[name].clampWhenFinished = true;
    });

    actions["OneBlink"].setLoop(THREE.LoopOnce, 1);
    actions["OneBlink"].clampWhenFinished = true;

    intervalId.current = setInterval(() => {
      idleAnimationNames.forEach((name) => {
        if (actions[name].paused) {
          // console.warn("STOPPED", name);
          onAnimationFinished(name)
        }
      });
    }, 1000); // Check every second

    playRandomIdleAnimation()

    return () => {
      clearInterval(intervalId.current);
    };

  }, [actions]);

  const handleStart = () => {
    intervalId.current = setInterval(() => {
      idleAnimationNames.forEach((name) => {
        if (actions[name].paused) {
          // console.warn("STOPPED", name);
          onAnimationFinished(name)
        }
      });
    }, 1000); // Check every second
  };

  const handleStop = () => {
    clearInterval(intervalId.current);
  };

  useEffect(() => {
    if (isThinking) {
      // console.log("isThinking", isThinking)
      isThinkingRef.current = true;

      // actions["Hovering"].fadeOut(0.5).stop();
      // actions["OneBlink"].fadeOut(0.5).stop();

      // // Stop idle animations and their corresponding face animations
      // idleAnimationNames.forEach((name) => {
      //   actions[name].fadeOut(0.5).stop();
      //   // actions[name].fadeOut(0.5);
      // });

      // idleFaceAnimationNames.forEach((name) => {
      //   actions[name].fadeOut(0.5).stop();
      //   // actions[name].fadeOut(0.5);
      // });

      handleStop()

      actions["OneBlink"].stop();

      actions["Hovering"].stop();
      // actions["OneBlink"].fadeOut(0.5).stop();

      idleAnimationNames.forEach((name) => {
        actions[name].stop();
        // actions[name].fadeOut(0.5);
      });

      idleFaceAnimationNames.forEach((name) => {
        actions[name].stop();
        // actions[name].fadeOut(0.5);
      });

      // console.log("PLAying thinking")

      actions["Thinking/processing"].play();
      actions["Thinking/processing_Face"].play();
    } else {
      isThinkingRef.current = false;
      // console.log("Stopped thinking")

      actions["Thinking/processing"].stop();
      actions["Thinking/processing_Face"].stop();
    }
  }, [isThinking, actions]);


  useEffect(() => {

    if (isTalking) {

      startTalkAnimation()
      actions["OneBlink"].stop();
      actions["Hovering"].stop();


      if (!greetMessage) setGreetMessage(true);


      isTalkingRef.current = true;
      // console.log("isTalking", isTalking)
      // console.log("PLAying talking")
      const talkingAnimations = ["Talking_1", "Talking_2", "Talking_3"];
      const randomIndex = Math.floor(Math.random() * talkingAnimations.length);
      const randomTalkingAnimation = talkingAnimations[randomIndex];

      actions[randomTalkingAnimation].play()
    } else {

      ["Talking_1", "Talking_2", "Talking_3"].forEach((talkingAnimation) => {
        actions[talkingAnimation].stop();
      });

      stopTalkAnimation()

      isTalkingRef.current = false;

      // console.log("Stopped talking")

      if (greetMessage) {
        // console.warn("first")
        playRandomIdleAnimation()
        handleStart()
      }

    }
  }, [isTalking, actions]);

  // useEffect(() => {
  //   playHoverAndEyes()
  // }, [actions])



  // UI
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 350) {
        setScale(5)
        setPositionY(-3.05)
      } else if (window.innerWidth < 768) {
        setScale(5.25)
        setPositionY(-3.20)
      } else if (window.innerWidth < 1200) {
        setScale(5.5)
        setPositionY(-3.35)
      } else {
        setScale(5.75)
        setPositionY(-3.55)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])



  const mesh = gltf.scene.children[0].children[0].children[5];
  // const blendShapes = Object.keys(mesh.morphTargetDictionary);
  // console.log(blendShapes);

  // console.log(actions)

  // Set initial target values for first five blend shapes
  const targetValues = [0.9, 0.8, 1, 0.25, 0.025].map((max) => Math.random() * max)
  const blinkTargetValue = useRef(0)

  // Keep track of whether the animation is running or not
  const [isRunning, setIsRunning] = useState(false)

  // Update first five blend shape weights on each frame
  useFrame(() => {
    if (isRunning) {
      for (let i = 0; i < 5; i++) {
        mesh.morphTargetInfluences[i] += (targetValues[i] - mesh.morphTargetInfluences[i]) * 0.1
      }
      mesh.morphTargetInfluences[11] += (blinkTargetValue.current - mesh.morphTargetInfluences[11]) * 0.1
    } else {
      for (let i = 0; i < 5; i++) {
        mesh.morphTargetInfluences[i] += (0 - mesh.morphTargetInfluences[i]) * 0.1
      }
      mesh.morphTargetInfluences[11] += (0 - mesh.morphTargetInfluences[11]) * 0.1
    }
  })

  // Update target values at regular intervals
  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning) {
        // Set new random target values for first five blend shapes
        targetValues[0] = Math.random() * 0.7
        targetValues[1] = Math.random() * 0.5
        targetValues[2] = Math.random() * 0.6
        targetValues[3] = Math.random() * 0.25
        targetValues[4] = Math.random() * 0.025
      }
    }, 150) // Adjust interval as needed

    return () => clearInterval(interval)
  }, [isRunning, targetValues])

  // Update target value for blink blend shape at regular intervals
  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning) {
        // Set target value for blink blend shape to 1
        blinkTargetValue.current = 1

        // Reset target value to 0 after a delay
        setTimeout(() => {
          blinkTargetValue.current = 0
        }, 200) // Adjust delay as needed
      }
    }, 3000) // Adjust interval as needed

    return () => clearInterval(interval)
  }, [isRunning])

  // Function to start the animation
  const startTalkAnimation = () => setIsRunning(true)

  // Function to stop the animation
  const stopTalkAnimation = () => setIsRunning(false)


  return <primitive ref={group} object={gltf.scene} scale={scale} position={[0, positionY, 0]} rotation={[0.2, 0, 0]} />
}

const BotCanvas = ({ isTalking, talkAnimaton, onLoad, onProgress, isThinking }) => {
  return (
    <div className="bot-cont">
      <Canvas>
        {/* <Environment preset='sunset' /> */}
        <ambientLight intensity={1} />
        <directionalLight position={[3.3, 30, 4.4]} intensity={6} />

        <Model isTalking={isTalking} talkAnimaton={talkAnimaton} onLoad={onLoad} onProgress={onProgress} isThinking={isThinking} />
      </Canvas>
    </div>
  )
}

export default BotCanvas